import styled from 'styled-components';
import { Link } from 'gatsby';
import { media } from 'themes';

export const Wrapper = styled.div`
  padding-top: 110px;

  ${media.md`
    padding-top: 60px;
  `}

  ${media.sm`
    padding: 0;
  `}
`;

export const Heading = styled.h2`
  color: #474c57;
  font-size: 32px;
  line-height: 52px;
  text-align: center;
  margin-bottom: 75px;

  ${media.sm`
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 48px;
  `}
`;

export const Feature = styled.div`
  text-align: center;
  margin-bottom: 70px;
`;

export const Icon = styled.img``;

export const Title = styled.div`
  margin: 48px 0 25px;
  color: #474c57;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;

  ${media.sm`
    font-size: 18px;
    line-height: 22px;
  `}
`;

export const Description = styled.div`
  color: #5a5f6a;
  font-size: 14px;
  line-height: 28px;
`;

export const Services = styled.div`
  border-radius: 157.5px;
  background-color: #75a605;
  box-shadow: 0 4px 8px -3px rgba(117, 166, 5, 0.52);
  padding: 45px 0;
  text-align: center;

  ${media.md`
    border-radius: 20px;
  `}

  ${media.sm`
    border-radius: 0;
    margin-left: -16px;
    margin-right: -16px;
  `}
`;

export const ServicesTitle = styled.div`
  color: #ffffff;
  font-size: 32px;
  line-height: 52px;

  ${media.sm`
    font-size: 21px;
    line-height: 35px;
    padding: 0 70px;
  `}
`;

export const Muted = styled.span`
  color: #9ac23f;
`;

export const ServicesDetails = styled.div`
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  padding: 0 300px;
  margin-top: 18px;

  ${media.md`
    padding: 0 200px;
  `}

  ${media.sm`
    padding: 0;
    font-size: 16px;
    padding: 0 20px;
  `}
`;

export const ServicesButton = styled(Link)`
  display: inline-block;
  margin-top: 28px;
  border-radius: 30.5px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px -2px rgba(78, 112, 0, 0.41);
  color: #405a02;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  padding: 8px 28px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
