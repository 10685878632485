import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/Seo';
import ContactBox from 'components/ContactBox';
import HappyClients from 'components/HappyClients';

// Content
import Header from 'content/Home/Header';
import Features from 'content/Home/Features';

const IndexPage = ({ location }) => (
  <Layout isHome={location.pathname === '/'}>
    <SEO title="Home" keywords={['homepage']} />

    <Header />
    <Features />
    <HappyClients topOffset />
    <ContactBox />
  </Layout>
);

export default IndexPage;
