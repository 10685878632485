import React from 'react';
import { Row, Col } from 'styled-bootstrap-grid';

import FileReferences from 'images/references.svg';
import FileReliable from 'images/reliable.svg';
import File24hGuarantee from 'images/24h-guarantee.svg';
import FileNoObligation from 'images/no-obligation.svg';
import Container from 'components/Container';

import {
  Wrapper,
  Heading,
  Feature,
  Icon,
  Title,
  Description,
  Services,
  ServicesTitle,
  ServicesDetails,
  ServicesButton,
  Muted,
} from './styled';

const Features = () => (
  <Wrapper>
    <Container>
      <Heading>Why Choose Joanna Cleaning Service?</Heading>

      <Row>
        <Col md={6} lg={3}>
          <Feature>
            <Icon src={FileReferences} />
            <Title>Excellent references</Title>
            <Description>
              For more than 10 years our standards did not change. We have
              created long lasting relationships with clients, so spoil yourself
              with the luxury of having an experienced professionals do the
              cleaning for you.
            </Description>
          </Feature>
        </Col>
        <Col md={6} lg={3}>
          <Feature>
            <Icon src={FileReliable} />
            <Title>Reliable and trustworthy</Title>
            <Description>
              Our cleaning ladies are always on time and consistent with a
              quality. Honest and hardworking people you can rely on.
            </Description>
          </Feature>
        </Col>
        <Col md={6} lg={3}>
          <Feature>
            <Icon src={File24hGuarantee} />
            <Title>24h guarantee</Title>
            <Description>
              If you're ever unhappy with any detail we've cleaned, call within
              24 hours and we'll rush back and re-clean it. That is the
              confidence we have that you will receive the most thorough
              cleaning service you deserve.
            </Description>
          </Feature>
        </Col>
        <Col md={6} lg={3}>
          <Feature>
            <Icon src={FileNoObligation} />
            <Title>No obligation</Title>
            <Description>
              You are able to suspend or cancel service at any time. We never
              ask you for any commitment or long-term contract.
            </Description>
          </Feature>
        </Col>
      </Row>

      <Row>
        <Col lg={10} lgOffset={1}>
          <Services>
            <ServicesTitle>
              Basic Cleaning <Muted>&amp;</Muted> Deep Cleaning
            </ServicesTitle>
            <ServicesDetails>
              Want to know the difference and&nbsp;learn more about our services?
            </ServicesDetails>

            <ServicesButton to="/services">
              Services &amp; Pricing Page
            </ServicesButton>
          </Services>
        </Col>
      </Row>
    </Container>
  </Wrapper>
);

export default Features;
