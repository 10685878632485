import styled from 'styled-components';
import Slider from 'react-slick';
import Img from 'gatsby-image';

import { media } from 'themes';

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  height: 530px;
`;

export const HeaderSlider = styled(Slider)`
  && {
    width: 100%;
    float: right;
    margin-top: -40px;

    .slick-list {
      overflow: visible;
    }

    .slick-slide {
      text-align: center;
    }

    ${media.sm`
      display: none !important;
    `}
  }
`;

export const MobileHeaderSlider = styled(Slider)`
  && {
    display: none;
    width: 100%;
    overflow: hidden;
    margin-top: -40px;

    .slick-list {
      overflow: visible;
    }

    .slick-slide {
      text-align: center;
    }

    ${media.sm`
      display: block;
    `}
  }
`;

export const HeaderImage = styled(Img)`
  && {
    float: right;
  }
`;

export const MobileHeaderImage = styled(Img)`
  display: none;

  ${media.sm`
    display: block;
    height: 263px;
      margin-left: -15px;
      margin-right: -15px;
  `}
`;

export const Box = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 288px;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 12px 34px 12px 24px;
  font-size: 14px;
  line-height: 18px;

  ${media.sm`
    position: relative;
    top: auto;
    right: auto;
    border-radius: 0;
    padding: 20px 0 0 0;
  `}
`;

export const Title = styled.div`
  color: #474c57;
  font-size: 14px;
  margin: 10px 0 5px;
`;

export const Description = styled.div`
  color: #6b7385;
  padding-bottom: 20px;
  border-bottom: 1px dashed #e3e3e3;
  margin-bottom: 20px;

  ${media.sm`
    border-bottom: 0;
  `}
`;

export const Social = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ArrownDown = styled.div`
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: #75a605;
  box-shadow: 0 4px 8px -2px rgba(117, 166, 5, 0.7);
  border-radius: 100%;

  ${media.sm`
    top: 0;
  `}
`;
