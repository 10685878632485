import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import FileLogoYelp from 'images/yelp-logo-color.svg';
import FileLogoInstagram from 'images/instagram.svg';
import FileChevronDown from 'images/chevron-down.svg';
import Container from 'components/Container';

import {
  Wrapper,
  HeaderImage,
  Box,
  Title,
  Description,
  Social,
  ArrownDown,
  MobileHeaderImage,
  HeaderSlider,
  MobileHeaderSlider,
} from './styled';

const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  fade: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  pauseOnHover: false,
};

const Header = () => (
  <Wrapper>
    <StaticQuery
      query={graphql`
        query {
          headerImage: file(relativePath: { eq: "homepage-header.png" }) {
            childImageSharp {
              fixed(height: 530, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
          headerImage2: file(relativePath: { eq: "bg-1.png" }) {
            childImageSharp {
              fixed(height: 530, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
          headerImage3: file(relativePath: { eq: "homepage-bg2.png" }) {
            childImageSharp {
              fixed(height: 530, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }

          headerImageFluid: file(relativePath: { eq: "homepage-header.png" }) {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          headerImageFluid2: file(relativePath: { eq: "bg-1.png" }) {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          headerImageFluid3: file(relativePath: { eq: "homepage-bg2.png" }) {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      `}
      render={data => (
        <>
          <HeaderSlider {...settings}>
            <Container>
              <HeaderImage
                fixed={data.headerImage.childImageSharp.fixed}
              />
            </Container>
            <Container>
              <HeaderImage
                fixed={data.headerImage2.childImageSharp.fixed}
              />
            </Container>
            <Container>
              <HeaderImage
                fixed={data.headerImage3.childImageSharp.fixed}
              />
            </Container>
          </HeaderSlider>

          <MobileHeaderSlider {...settings}>
            <Container>
              <MobileHeaderImage
                fluid={data.headerImageFluid.childImageSharp.fluid}
              />
            </Container>
            <Container>
              <MobileHeaderImage
                fluid={data.headerImageFluid2.childImageSharp.fluid}
              />
            </Container>
            <Container>
              <MobileHeaderImage
                fluid={data.headerImageFluid3.childImageSharp.fluid}
              />
            </Container>
          </MobileHeaderSlider>
        </>
      )}
    />

    <Container>
      <ArrownDown>
        <img src={FileChevronDown} alt="" />
      </ArrownDown>

      <Box>
        <Title>Attention!</Title>
        <Description>
          Please be aware, we do not sell any deals on Groupon site!
        </Description>

        <Social>
          <a
            href="https://www.yelp.com/biz/joanna-cleaning-service-brooklyn-2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={FileLogoYelp} alt="Yelp" />
          </a>
          <a
            href="https://www.instagram.com/joanna_cleaning_service_/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={FileLogoInstagram} alt="Instagram" />
          </a>
        </Social>
      </Box>
    </Container>
  </Wrapper>
);

export default Header;
